import React from 'react'
import styles from "./index.module.scss"

const ProfileText = () => (
  <div>
    <p className={styles.description}>
      「おおなみ まこと」と読みます。1979年生まれです。
    </p>
    <p className={styles.description}>
      超ド近眼で視力は0.01を切ってます。メガネの厚みは相当なものです。
      <br></br>
      メガネとは小学校5年生の頃からのお付き合いです。
    </p>
    <p className={styles.description}>
      ポジティブな孤独のあり方を考え、追求しています。
      <br></br>
      日々の料理、体を維持するための筋トレが好きです。
    </p>
    <p className={styles.description}>
      アプリやWebサービスを作リ出し運営することで
      <br></br>
      世の中にほんの少しでも貢献していきたいと思っています。
      </p>
    <p className={styles.description}>
      今熱中しているのは個人開発です。
      <br></br>
      企画、デザイン、設計・開発、テスト、マーケティングやユーザーサポート、運用保守などプロダクトに関する全てのことに興味関心があります。
    </p>
    <p className={styles.description}>
      会社員としてのキャリアは<a href="https://www.wantedly.com/users/1236016" className={styles.link}>こちら</a>をご覧ください。
    </p>
    <p className={styles.description}>
      東京都区内におりますので、近くの方はぜひお茶などお気軽にお声がけいただけると幸いです。僕はルノアールが大好きです。
    </p>
    <p className={styles.description}>
      stand.fmをはじめました。
      <br />
      自分のプロダクトの進捗や課題や方向性、その他雑談など、3分程度でゆるいトークを公開しています。
      <br />
      <br />
      休憩のときにでも、聴いていただけたら幸いです。
      <br />
      <a href="https://stand.fm/channels/5e66157e81d4e84e1f696788" className={styles.link}>おおなみ開発レポート</a>
    </p>
  </div>
)
export default ProfileText
