import React from "react"

import Layout from "../components/layout"
import Heading1 from "../components/Heading1"
import Heading2 from "../components/Heading2"
import MyPhoto from "../components/MyPhoto"
import ProfileText from "../components/about/ProfileText"
import SEO from "../components/seo"
import styles from "./about.module.scss"

const AboutPage = () => (
  <Layout>
    <SEO title="個人開発者 大波 誠(おおなみ まこと)のプロフィール" />
    
    <Heading1>プロフィール</Heading1>
    <div className={styles.block}>
      <MyPhoto></MyPhoto>
    </div>
    <div className={styles.block}>
      <Heading2 text="大波 誠 / Makoto Ohnami"></Heading2>
      <div className={styles.block__description}>
        <ProfileText></ProfileText>
      </div>
    </div>
  </Layout>
)

export default AboutPage
